import React, { useState, useCallback, useEffect } from 'react';

import {
  Button,
  Drawer,
  Box,
  Typography,
  FormControl,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';

import api from '../../services/api';

import { successMessage$, errorMessage$ } from '../../utils/subjects';

export default function ClosureDialog({ open, handleClose }) {
  const [splitPromotion, setSplitPromotion] = useState(new Date());
  const [errorMessage, setErrorMessage] = useState('');
  const [isMonthlyClosure, setIsMonthlyClosure] = useState(false);
  const [
    openConfirmGenerateWeeklyClosure,
    setOpenConfirmGenerateWeeklyClosure,
  ] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [hasCompleted, setHasCompleted] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(
    `Are you sure you want to generate this week's closure?
If we are in promotions phase, then please make sure that promotions are already in place with the IT Team before doing this action.`,
  );

  const [dotCount, setDotCount] = useState(1);
  useEffect(() => {
    let interval;
    if (isGenerating) {
      interval = setInterval(() => {
        setDotCount(d => (d % 3) + 1);
      }, 500);
    } else {
      setDotCount(1);
    }
    return () => clearInterval(interval);
  }, [isGenerating]);
  const processingButtonText = `Processing, please wait${'.'.repeat(dotCount)}`;

  const handleSubmit = useCallback(async () => {
    try {
      await api.post('scheduling/schedule-weeks/closures', {
        splitPromotion,
        isMonthlyClosure,
      });

      successMessage$.next({
        open: true,
        message: 'Success on weekly closure request. Please, reload the page.',
      });
    } catch (err) {
      errorMessage$.next({
        open: true,
        message: `Error: ${err.response}`,
      });
      setErrorMessage(`Error: ${err.response}`);
      throw err;
    }
  }, [splitPromotion, isMonthlyClosure]);

  const handleGenerate = async () => {
    setIsGenerating(true);
    setDialogMessage(
      'We are processing your solicitation, please wait a moment',
    );

    try {
      await handleSubmit();

      setDialogMessage(
        `Thank you for your request. We are currently carrying out the necessary steps to complete the closure.
You will soon receive a confirmation email with all the data that was handled during this process.
If you have any questions, please contact the IT team.
You may now close this window!`,
      );
      setHasCompleted(true);
    } catch (e) {
      setIsGenerating(false);
      return;
    }

    setIsGenerating(false);
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        style={{ position: 'relative' }}
      >
        <Box m={2} width={300}>
          <Typography
            variant="h5"
            component="h5"
            style={{ fontWeight: 500, marginBottom: '15px' }}
          >
            Weekly Closure
          </Typography>

          <Alert severity="info" style={{ marginTop: 15 }}>
            <Typography variant="inherit" component="p">
              If don't have any promotions, this field will be ignored.
            </Typography>
          </Alert>

          <FormControl fullWidth style={{ marginTop: 15 }}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MMM/yyyy"
              margin="normal"
              id="date-picker-start-date"
              label="Split Promotion Date"
              value={splitPromotion}
              onChange={date => setSplitPromotion(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </FormControl>

          <FormControl fullWidth style={{ marginTop: 15 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isMonthlyClosure}
                  onChange={event => setIsMonthlyClosure(event.target.checked)}
                  name="isMonthlyClosure"
                  color="primary"
                />
              }
              label="Monthly Closure"
            />
          </FormControl>

          {errorMessage !== '' && (
            <Alert severity="error" style={{ marginTop: 15 }}>
              <Typography variant="inherit" component="p">
                {errorMessage}
              </Typography>
            </Alert>
          )}

          <div style={{ position: 'absolute', bottom: 15, right: 15 }}>
            <Button
              onClick={handleClose}
              size="small"
              style={{ marginRight: 15 }}
              disabled={isGenerating}
            >
              Close
            </Button>

            <Button
              onClick={() => setOpenConfirmGenerateWeeklyClosure(true)}
              variant="contained"
              color="primary"
              size="small"
              disabled={isGenerating}
            >
              Generate
            </Button>
          </div>
        </Box>
      </Drawer>

      <Dialog
        open={openConfirmGenerateWeeklyClosure}
        onClose={
          isGenerating
            ? () => {}
            : () => setOpenConfirmGenerateWeeklyClosure(false)
        }
      >
        <DialogTitle>Confirm Weekly Closure</DialogTitle>
        <DialogContent>
          <Typography style={{ fontWeight: 500, whiteSpace: 'pre-line' }}>
            {dialogMessage}
          </Typography>
        </DialogContent>

        <DialogActions>
          {!isGenerating && !hasCompleted && (
            <Button
              style={{ marginRight: 15 }}
              onClick={() => setOpenConfirmGenerateWeeklyClosure(false)}
            >
              Cancel
            </Button>
          )}

          {hasCompleted ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenConfirmGenerateWeeklyClosure(false)}
            >
              Close
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleGenerate}
              disabled={isGenerating}
              startIcon={isGenerating ? <CircularProgress size={20} /> : null}
            >
              {isGenerating ? processingButtonText : 'Generate'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
