import React, { useCallback, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, InputAdornment } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import CustomStore from 'devextreme/data/custom_store';
import { SelectBox } from 'devextreme-react';
import NumberFormatValue from '../../../components/NumberFormat';

import { errorMessage$, successMessage$ } from '../../../utils/subjects';
import api from '../../../services/api';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

interface DialogAuxJobTitleEditProps {
  handleClose(): void;
  handleOpen(): void;
  callback(): void;
  open: boolean;
  professionalJobtitleId: number;
}

export const DialogAuxJobTitleEdit: React.FC<DialogAuxJobTitleEditProps> = ({
  handleClose,
  handleOpen,
  open,
  callback,
  professionalJobtitleId,
}) => {
  const classes = useStyles();

  const [professional, setProfessional] = useState(0);
  const [jobtitle, setJobtitle] = useState(0);

  const [professionalSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get('master/professionals');

        return data;
      },
    }),
    paginate: true,
  });

  const [jobtitleSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get('master/jobtitles');

        return data;
      },
    }),
    paginate: true,
  });

  useEffect(() => {
    const loadProfessionalJobtitle = async () => {
      try {
        const { data } = await api.get(
          `scheduling/professional-jobtitles/${professionalJobtitleId}`,
        );

        setProfessional(data.Professional.ID);
        setJobtitle(data.Position);
      } catch (err) {
        errorMessage$.next({
          open: true,
          message: 'Error to get tranfer price information',
        });
      }
    };

    loadProfessionalJobtitle();
  }, [professionalJobtitleId]);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();

      try {
        const data = {
          Professional: professional,
          Position: jobtitle,
        };

        handleClose();
        await api.put(
          `scheduling/professional-jobtitles/${professionalJobtitleId}`,
          data,
        );

        successMessage$.next({
          open: true,
          message: 'Aux Jobtitle updated successfully',
        });
        if (callback) callback();
      } catch (err) {
        errorMessage$.next({
          open: true,
          message: 'Error on update aux jobtitle',
        });
      }
    },
    [callback, handleClose, professional, jobtitle],
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      style={{ minWidth: 200 }}
    >
      <DialogTitle id="form-dialog-title">Edit aux jobtitle</DialogTitle>
      <form className={classes.root} onSubmit={handleSubmit}>
        <DialogContent style={{ minWidth: 400 }}>
          <SelectBox
            dataSource={professionalSource}
            displayExpr="Name"
            valueExpr="ID"
            placeholder="Select the professional"
            searchExpr={['Name']}
            minSearchLength={2}
            showDataBeforeSearch
            searchEnabled
            value={professional}
            onValueChanged={e => {
              setProfessional(e.value);
            }}
            style={{ marginBottom: 24 }}
          />

          <SelectBox
            dataSource={jobtitleSource}
            displayExpr="Name"
            valueExpr="ID"
            placeholder="Select the jobtitle"
            searchExpr={['Name']}
            minSearchLength={2}
            showDataBeforeSearch
            searchEnabled
            value={jobtitle}
            onValueChanged={e => {
              setJobtitle(e.value);
            }}
            style={{ marginBottom: 24 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
