import {
  getWeekOfMonth,
  startOfWeek,
  endOfWeek,
  parseISO,
  getDay,
  isFirstDayOfMonth,
  isSameMonth,
  subWeeks,
  getWeek as dateFnsGetWeek,
  getWeekYear as dateFnsGetWeekYear,
  setWeek as dateFnsSetWeek,
  setWeekYear as dateFnsSetWeekYear,
  subDays,
} from 'date-fns';

export function isFirstFullMonthWeek(date) {
  const startWeekDate = startOfWeek(date);
  const endWeekDate = endOfWeek(date);
  const previousStartWeekDate = subWeeks(startWeekDate, 1);
  const previousEndWeekDate = subWeeks(endWeekDate, 1);

  if (
    getWeekOfMonth(startWeekDate) === 1 &&
    isFirstDayOfMonth(startWeekDate) &&
    isSameMonth(startWeekDate, endWeekDate)
  ) {
    return true;
  }

  if (
    getWeekOfMonth(startWeekDate) === 2 &&
    isSameMonth(startWeekDate, endWeekDate) &&
    !isSameMonth(previousStartWeekDate, previousEndWeekDate)
  ) {
    return true;
  }

  return false;
}

function isYearWithWeekStartingOnLastYear(year) {
  // Get the day of the week of January 1st (0 is Sunday, 1 is Monday, ..., 6 is Saturday)
  const dayOfFirstJan = getDay(parseISO(`${year}-01-01`));
  // If January 1st is Saturday (6) or Sunday (0), the first week starts in the previous year
  return dayOfFirstJan === 6 || dayOfFirstJan === 0;
}

export function getWeek(date) {
  // Adjust the date to the previous Sunday
  const dayOfWeek = date.getDay();
  const previousSunday = subDays(date, dayOfWeek);

  const year = previousSunday.getFullYear();
  const firstWeekContainsDate = isYearWithWeekStartingOnLastYear(year) ? 1 : 7;

  let weekNumber = dateFnsGetWeek(previousSunday, {
    weekStartsOn: 0,
    firstWeekContainsDate,
  });
  if (year === 2024) {
    weekNumber += 1;
  }

  return weekNumber;
}

export function getWeekYear(date) {
  const year = date.getFullYear();
  const firstWeekContainsDate = isYearWithWeekStartingOnLastYear(year) ? 1 : 7;

  return dateFnsGetWeekYear(date, { weekStartsOn: 0, firstWeekContainsDate });
}

export function setWeek(date, week) {
  const year = date.getFullYear();
  const firstWeekContainsDate = isYearWithWeekStartingOnLastYear(year) ? 4 : 1;

  return dateFnsSetWeek(date, week, { firstWeekContainsDate });
}

export function setWeekYear(date, weekYear) {
  const year = date.getFullYear();
  const firstWeekContainsDate = isYearWithWeekStartingOnLastYear(year) ? 4 : 1;

  return dateFnsSetWeekYear(date, weekYear, { firstWeekContainsDate });
}
